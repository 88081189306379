//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'

export default {
  name: 'App',
  computed: mapState({
    user: 'user',
    room: 'room',
    appReady: (state) => !!state.auth.csrfToken,
    previousLogin: (state) => state.auth.haveLoggedIn
  }),
  components: {
    MainHeader: () => import('@/components/header/MainHeader.vue'),
    MainFooter: () => import('@/components/footer/MainFooter.vue'),
    PlaylistDrawer: () => import('@/components/playlist/PlaylistDrawer.vue'),
    UsersDrawer: () => import('@/components/users/UsersDrawer.vue')
  }
}
