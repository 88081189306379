//
//
//
//
//
//
//
//

import { mapState } from 'vuex'

import LandingPage from '@/views/LandingPage.vue'
import LoadingPage from '@/views/LoadingPage.vue'

export default {
  name: 'Home',
  computed: mapState({
    user: 'user',
    ready: (state) => !!state.auth.csrfToken,
    haveLoggedIn: (state) => state.auth.haveLoggedIn
  }),
  components: {
    LandingPage,
    MainArea: () => import('@/components/chat/MainArea.vue'),
    LoadingPage
  }
}
