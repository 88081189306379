//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
export default {
  name: 'LandingPage',
  components: {
    MoreDetails: () => import('./MoreDetails.vue')
  },
  methods: {
    login() {
      sessionStorage.loginRetried = 'no'
      window.location.href = this.authUrl
    },
    faq() {
      this.$refs.scrollArea.setScrollPosition(930, 200)
    }
  },
  computed: mapGetters(['authUrl']),
  mounted() {
    const match = window.location.pathname.match(/^\/([^/]+)\/*$/)
    if (match) {
      sessionStorage.path = `/${match[1]}`
    } else {
      sessionStorage.path = '/'
    }
    sessionStorage.path += window.location.search
  }
}
