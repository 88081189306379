import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Main.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/:room',
    name: 'Room',
    component: Home
  },
  {
    path: '/auth/callback',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/auth/nopremium',
    name: 'NoPremium',
    component: () => import('../views/NoPremium.vue')
  },
  {
    path: '/content/faq',
    name: 'MoreDetails',
    component: () => import('../views/MoreDetails.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
