import Debug from 'debug'

const debug = Debug('groove:spotify:player')

export default {
  state: {
    sdkReady: false,
    name: 'Web Player',
    sdkPlayerId: null,
    currentPlayer: null,
    availablePlayers: [],
    webPlayer: null,
    playing: false,
    volume: 80
  },
  getters: {
    volume: (state) => state.volume,
    webPlayer: (state) => state.webPlayer
  },
  mutations: {
    SET_WEB_PLAYER_ID(state, playerId) {
      state.playerId = playerId
    },
    SET_WEB_PLAYER(state, player) {
      state.webPlayer = player
    },
    SDK_READY(state) {
      state.sdkReady = true
    },
    UPDATE_PLAYERS(state, players) {
      state.availablePlayers = players.filter((player) => !player.is_restricted)
    },
    SET_CURRENT_PLAYER(state, player) {
      state.currentPlayer = player
    },
    SET_VOLUME(state, volume) {
      state.volume = Math.round(volume * 100)
    },
    SET_PLAYING(state, playing) {
      state.playing = playing
    }
  },
  actions: {
    spotifyWebPlaybackSDKReady({ commit }) {
      debug('spotify web playback SDK is ready')
      commit('SDK_READY')
    },
    startPlaying({ commit }) {
      commit('SET_PLAYING', true)
    },
    stopPlaying({ commit }) {
      commit('SET_PLAYING', false)
    },
    async webPlayerReady({ state, commit, dispatch }, { deviceId, player }) {
      commit('SET_WEB_PLAYER_ID', deviceId)
      commit('SET_WEB_PLAYER', player)
      await dispatch('updatePlayers')
      if (!state.currentPlayer) {
        await dispatch('setCurrentPlayer', deviceId)
      }
    },
    async updatePlayers({ getters, state, commit }) {
      const res = await getters.spotifyApi.getMyDevices()
      commit('UPDATE_PLAYERS', res.devices)
      const [activePlayer] = res.devices.filter((player) => player.is_active)
      if (activePlayer) {
        if (
          !state.currentPlayer ||
          state.currentPlayer.id !== activePlayer.id
        ) {
          commit('SET_CURRENT_PLAYER', activePlayer)
        }
        commit('SET_VOLUME', activePlayer.volume_percent)
      }
    },
    async setVolume({ getters, state, commit }, volume) {
      commit('SET_VOLUME', volume)
      try {
        if (state.currentPlayer) {
          await getters.spotifyApi.setVolume(volume, {
            device_id: state.currentPlayer.id
          })
        }
      } catch (err) {
        debug('error setting playback volume: %o', err)
      }
    },
    async setCurrentPlayer({ state, getters, commit }, playerId) {
      const [player] = state.availablePlayers.filter(
        (player) => player.id === playerId
      )
      if (player) {
        await getters.spotifyApi.transferMyPlayback([player.id])
        commit('SET_CURRENT_PLAYER', player)
      }
    }
  }
}
