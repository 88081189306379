import Firebase from './Firebase.js'

export default {
  setupPresence,
  clearPresence
}

function setupPresence() {
  const { Auth, DB, Rtdb, Firebase: firebase } = Firebase

  const isOfflineForDatabase = {
    state: 'offline',
    last_changed: firebase.database.ServerValue.TIMESTAMP
  }
  const isOnlineForDatabase = {
    state: 'online',
    last_changed: firebase.database.ServerValue.TIMESTAMP
  }
  const isOfflineForFirestore = {
    status: {
      state: 'offline',
      last_changed: firebase.firestore.FieldValue.serverTimestamp()
    }
  }
  const isOnlineForFirestore = {
    status: {
      state: 'online',
      last_changed: firebase.firestore.FieldValue.serverTimestamp()
    }
  }

  const uid = Auth.currentUser.uid
  if (!uid) {
    throw new Error('Unable to get current user id from Firebase auth')
  }

  const rtdbRef = Rtdb.ref(`/status/${uid}`)
  const firestoreRef = DB.collection('users').doc(uid)
  const statusRef = Rtdb.ref('.info/connected')

  statusRef.on('value', (snapshot) => {
    if (snapshot.val() === false) {
      firestoreRef.set(isOfflineForFirestore, { merge: true })
      return
    }

    rtdbRef
      .onDisconnect()
      .set(isOfflineForDatabase)
      .then(() => {
        rtdbRef.set(isOnlineForDatabase)
        firestoreRef.set(isOnlineForFirestore, { merge: true })
      })
  })

  return { rtdbRef, statusRef }
}

function clearPresence(refs) {
  const { Firebase: firebase } = Firebase

  const isOfflineForDatabase = {
    state: 'offline',
    last_changed: firebase.database.ServerValue.TIMESTAMP
  }
  const isOfflineForFirestore = {
    status: {
      state: 'offline',
      last_changed: firebase.firestore.FieldValue.serverTimestamp()
    }
  }

  if (refs.rtdbRef) {
    refs.rtdbRef.off()
    refs.rtdbRef.set(isOfflineForDatabase)
  }
  if (refs.statusRef) {
    refs.statusRef.off()
    refs.statusRef.set(isOfflineForFirestore, { merge: true })
  }
}
