import Debug from 'debug'
const debug = Debug('groove:firebase')

const FirebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: `${process.env.VUE_APP_FIREBASE_PROJECT_ID}.firebaseapp.com`,
  databaseURL: `https://${process.env.VUE_APP_FIREBASE_PROJECT_ID}.firebaseio.com`,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: `${process.env.VUE_APP_FIREBASE_PROJECT_ID}.appspot.com`,
  messagingSenderId: process.env.VUE_APP_FIREBASE_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
}
const Firebase = {
  DB: null,
  Rtdb: null,
  Auth: null,
  Firebase: null
}

async function initialize() {
  try {
    const firebase = await import('firebase/app')
    await import('firebase/analytics')
    await import('firebase/auth')
    await import('firebase/firestore')
    await import('firebase/database')

    firebase.initializeApp(FirebaseConfig)
    firebase.analytics()
    firebase.firestore.setLogLevel('error')

    Firebase.Firebase = firebase
    Firebase.DB = firebase.firestore()
    Firebase.Rtdb = firebase.database()
    Firebase.Auth = firebase.auth()
    debug('firebase api is initialised')
  } catch (err) {
    debug('failed to initialise firebase: %o', err)
  }
}

Firebase.initialize = initialize

export default Firebase
