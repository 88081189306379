import Debug from 'debug'
import Firebase from '../lib/Firebase.js'
import { firestoreAction } from 'vuexfire'
import Router from '../router'

export default {
  state: {
    id: null,
    joinTime: Date.now(),
    members: [], // dynamic
    playlist: [], // dynamic
    messages: [], // dynamic
    usersOnline: [], // dynamic
    data: {}, // dynamic
    maxTracksPerUser: 3,
    currentTrackIndex: 0,
    usersVisible: false,
    playlistVisible: false
  },
  mutations: {
    SET_ROOM(state, room) {
      if (state.id !== room) {
        state.currentTrackIndex = 0
        state.id = room
        state.joinTime = Date.now()
      }
    },
    UPDATE_TRACK_INDEX(state, index) {
      state.currentTrackIndex = index
    },
    SET_USERS_VISIBLE(state, visible) {
      state.usersVisible = visible
    },
    SET_PLAYLIST_VISIBLE(state, visible) {
      state.playlistVisible = visible
    }
  },
  actions: {
    setRoom: firestoreAction(
      async (
        {
          state,
          commit,
          dispatch,
          bindFirestoreRef,
          unbindFirestoreRef,
          getters
        },
        room
      ) => {
        const { DB } = Firebase
        if (room) {
          try {
            await getters.apiRequest('post', '/v1/room', { room })
          } catch (err) {
            if (err.response && err.response.status === 403) {
              Debug('groove:api:error')(
                `got forbidden status joining room ${room}`
              )
              Debug('groove:navigate')(
                'redirecting to root due to room join forbidden'
              )
              Router.push('/')
              return
            } else {
              if (err.response && err.response.status >= 400) {
                throw new Error(
                  `bad status code from join room: ${err.response.status}`
                )
              }
              throw new Error(`unexpected error joining room: ${err}`)
            }
          }
        } else {
          await getters.apiRequest('delete', '/v1/room')
        }
        commit('SET_ROOM', room)
        if (!room) {
          return Promise.all([
            unbindFirestoreRef('playlist'),
            unbindFirestoreRef('messages'),
            unbindFirestoreRef('members'),
            unbindFirestoreRef('usersOnline'),
            unbindFirestoreRef('data')
          ])
        }

        const isPrivate = room[0] === ':'
        const collection = isPrivate ? 'privateRooms' : 'rooms'
        const binds = [
          bindFirestoreRef(
            'members',
            DB.collection(collection).doc(state.id).collection('members')
          ),
          bindFirestoreRef(
            'usersOnline',
            DB.collection('users')
              .where('room', '==', state.id)
              .where('status.state', '==', 'online')
          ),
          bindFirestoreRef(
            'playlist',
            DB.collection(collection)
              .doc(state.id)
              .collection('tracks')
              .where('endTime', '>', state.joinTime)
              .orderBy('endTime', 'asc')
          ),
          bindFirestoreRef(
            'messages',
            DB.collection(collection)
              .doc(state.id)
              .collection('messages')
              .where('time', '>', state.joinTime)
              .orderBy('time', 'asc')
          ),
          bindFirestoreRef('data', DB.collection(collection).doc(state.id))
        ]

        return Promise.all(binds)
      }
    ),
    async sendMessage({ getters }, message) {
      await getters.apiRequest('post', '/v1/message', { message })
    },
    async addTrack({ getters }, track) {
      await getters.apiRequest('post', '/v1/track', { track })
    },
    async removeTrack({ getters }, trackId) {
      await getters.apiRequest('delete', `/v1/track/${trackId}`)
    },
    setTime({ state, commit }, time) {
      let index = state.currentTrackIndex
      while (
        state.playlist[index] &&
        state.playlist[index].endTime < Date.now()
      ) {
        index++
      }
      if (index !== state.currentTrackIndex) {
        commit('UPDATE_TRACK_INDEX', index)
      }
    },
    toggleUsersVisible({ state, commit }) {
      commit('SET_USERS_VISIBLE', !state.usersVisible)
    },
    setUsersVisible({ commit }, visible) {
      commit('SET_USERS_VISIBLE', visible)
    },
    togglePlaylistVisible({ state, commit }) {
      commit('SET_PLAYLIST_VISIBLE', !state.playlistVisible)
    },
    setPlaylistVisible({ commit }, visible) {
      commit('SET_PLAYLIST_VISIBLE', visible)
    }
  }
}
