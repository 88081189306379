import Moment from 'moment'
import Router from '../router'
import Firebase from '../lib/Firebase.js'
import Debug from 'debug'

const debug = Debug('groove:auth')

export default {
  state: {
    firebaseToken: null,
    spotifyToken: null,
    csrfToken: null,
    tokensLastUpdated: null,
    oauthUrl: null,
    haveLoggedIn: false
  },
  mutations: {
    SET_TOKENS(state, { firebaseToken, spotifyToken }) {
      state.firebaseToken = firebaseToken
      state.spotifyToken = spotifyToken
      state.tokensLastUpdated = Moment()
      state.haveLoggedIn = true
    },
    CLEAR_TOKENS(state) {
      state.firebaseToken = null
      state.spotifyToken = null
      state.tokensLastUpdated = null
    },
    SET_URL(state, oauthUrl) {
      state.oauthUrl = oauthUrl
    },
    SET_CSRF(state, csrfToken) {
      state.csrfToken = csrfToken
    },
    SET_HAVELOGGEDIN(state, haveLoggedIn) {
      state.haveLoggedIn = haveLoggedIn === true || haveLoggedIn === 'true'
    }
  },
  actions: {
    async getTokens({ getters, commit, dispatch }) {
      try {
        const res = await getters.apiRequest('get', '/v1/auth')
        const { csrfToken, firebaseToken, spotifyToken, user, url } = res.data
        if (firebaseToken && spotifyToken) {
          await dispatch('setLoginTokens', {
            firebaseToken,
            spotifyToken
          })
          dispatch('setUser', user)
        }
        if (csrfToken) {
          commit('SET_CSRF', csrfToken)
        }
        if (url) {
          commit('SET_URL', url)
        }
      } catch (err) {
        debug('failure to get tokens: %o', err)
        commit('CLEAR_TOKENS')
        dispatch('setUser', null)
        Debug('groove:navigate')('token fetch failure so navigating to root')
        return Router.push('/')
      }
    },
    async finishAuth({ state, getters, commit, dispatch }, { code, token }) {
      try {
        debug('processing auth callback')
        const res = await getters.apiRequest('post', '/v1/auth', {
          code,
          token
        })
        const { firebaseToken, spotifyToken, user } = res.data
        debug('setting spotify and firebase tokens')
        await dispatch('setLoginTokens', {
          firebaseToken,
          spotifyToken
        })
        debug('setting user from auth response: %s', user)
        await dispatch('setUser', user)
        Debug('groove:navigate')(
          'post-login navigate to stored path %s',
          sessionStorage.path || '/'
        )
        return Router.push(sessionStorage.path || '/')
      } catch (err) {
        debug('Something went wrong during login: %o', err)
        let response = err
        if (response.response) {
          response = response.response
          debug('error has response: %o', response)
        }
        if (
          response.status === 403 &&
          response.data.reason === 'premiumRequired'
        ) {
          Debug('groove:navigate')(
            'backend reports no premium account - redirect to info page'
          )
          return Router.replace('/auth/nopremium')
        } else {
          if (sessionStorage.loginRetried === 'no') {
            sessionStorage.loginRetried = 'yes'
            window.location.href = state.oauthUrl
            return
          }
          Debug('groove:navigate')('login failed - redirect back to front page')
          return Router.replace('/')
        }
      }
    },
    async setLoginTokens({ commit }, { firebaseToken, spotifyToken }) {
      localStorage.haveLoggedIn = true
      commit('SET_TOKENS', { firebaseToken, spotifyToken })
      try {
        const { Auth } = Firebase
        debug('signing in to firebase with custom token')
        await Auth.signInWithCustomToken(firebaseToken)
      } catch (err) {
        debug('firebase signin failed with error: %o', err)
      }
    },
    async logout({ commit, dispatch, getters }) {
      try {
        const { Auth } = Firebase
        await getters.apiRequest('delete', '/v1/auth')
        await dispatch('setUser', null)
        localStorage.haveLoggedIn = false
        Auth.signOut()
        commit('CLEAR_TOKENS')
        debug('signed out')
      } catch (err) {
        debug('error signing out: %o', err)
      }
    },
    setPreviousLogin({ commit }, haveLoggedIn) {
      commit('SET_HAVELOGGEDIN', haveLoggedIn)
    }
  }
}
