import Vue from 'vue'
import Vuex from 'vuex'
import Debug from 'debug'

import SpotifyWebApi from 'spotify-web-api-js'
import Moment from 'moment'
import Axios from 'axios'

import User from './user.js'
import Room from './room.js'
import Player from './player.js'
import Search from './search.js'
import Auth from './auth.js'

import { vuexfireMutations } from 'vuexfire'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    appTime: Moment(),
    emojiData: null
  },
  modules: {
    auth: Auth,
    user: User,
    room: Room,
    player: Player,
    search: Search
  },
  mutations: {
    ...vuexfireMutations,
    UPDATE_APP_TIME(state, time) {
      state.appTime = time
    },
    SET_EMOJI_DATA(state, emojiData) {
      state.emojiData = emojiData
    }
  },
  getters: {
    spotifyApi(state) {
      const token = state.user.id && state.auth.spotifyToken
      if (token) {
        const api = new SpotifyWebApi()
        api.setAccessToken(token)
        return api
      }
    },
    apiRequest(state) {
      return (method, path, payload) => {
        Debug('groove:api')('http request', { method, path, payload })
        return Axios({
          method,
          headers: { 'csrf-token': state.auth.csrfToken },
          url: `${process.env.VUE_APP_BACKEND}${path}`,
          data: payload,
          withCredentials: true
        })
      }
    },
    authUrl(state) {
      return state.auth.oauthUrl
    }
  },
  actions: {
    setTime({ commit }, time) {
      commit('UPDATE_APP_TIME', time)
    },
    async init({ commit }) {
      const EmojiData = await import('emoji-mart-vue-fast/data/all.json')
      const emojiData = {}
      for (const key of Object.keys(EmojiData)) {
        emojiData[key] = EmojiData[key]
      }
      commit('SET_EMOJI_DATA', emojiData)
    }
  }
})
