import Vue from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'
import Moment from 'moment'
import MomentDurationFormat from 'moment-duration-format'
import { Dark } from 'quasar'
import { firestorePlugin } from 'vuefire'
import Debug from 'debug'
import 'circular-std'

import './quasar'

import './lib/Rollbar.js'
import Firebase from './lib/Firebase.js'

/* global Spotify */

localStorage.debug = process.env.VUE_APP_DEBUG
const debug = Debug('groove:init')

MomentDurationFormat(Moment)
Dark.set(true)

Vue.config.productionTip = false

window.onSpotifyWebPlaybackSDKReady = function () {
  Vue.prototype.Player = Spotify.Player
  debug('spotify web player ready')
  store.dispatch('spotifyWebPlaybackSDKReady')
}

Vue.directive('focus', {
  inserted: function (el) {
    el.focus()
  }
})

debug('initializing firebase')
Firebase.initialize()
  .then(() => {
    debug('booting vue app')
    new Vue({
      router,
      store,
      render: (h) => h(App),
      created() {
        Vue.use(firestorePlugin)
        debug('setting up with local storage %o', localStorage)
        store.dispatch('setPreviousLogin', localStorage.haveLoggedIn)
        setInterval(() => {
          store.dispatch('setTime', Moment())
        }, 1000)
        setInterval(() => {
          store.dispatch('getTokens')
        }, 1000 * 60 * 30)
        store.dispatch('getTokens')
        store.dispatch('init')
      }
    }).$mount('#app')
  })
  .catch((err) => debug('failed to initialise firebase: %o', err))
