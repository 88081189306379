export default {
  state: {
    query: '',
    results: []
  },
  mutations: {
    ADD_SEARCH_RESULTS(state, results) {
      state.results = state.results.concat(results)
    },
    SET_SEARCH_QUERY(state, query) {
      state.query = query
      state.results = []
    }
  },
  actions: {
    setQueryText({ commit }, text) {
      commit('SET_SEARCH_QUERY', text)
    },
    addSearchResults({ commit }, results) {
      commit('ADD_SEARCH_RESULTS', results)
    }
  }
}
