//
//
//
//

import LoadingIcon from '../components/util/LoadingIcon.vue'

export default {
  components: {
    LoadingIcon
  }
}
