import Firebase from '../lib/Firebase.js'
import { firestoreAction } from 'vuexfire'
import Presence from '../lib/Presence.js'

export default {
  state: {
    id: null,
    userData: null, // firestore
    memberships: [], // firestore,
    firebasePresenceRefs: {}
  },
  mutations: {
    SET_USER(state, user) {
      state.id = user
    },
    SET_PRESENCE_REFS(state, refs) {
      state.firebasePresenceRefs = refs
    }
  },
  getters: {
    groups(state) {
      return state.memberships.map((membership) => membership.groupRef)
    }
  },
  actions: {
    setUser: firestoreAction(
      async ({ state, commit, bindFirestoreRef, unbindFirestoreRef }, user) => {
        const { DB } = Firebase
        if (user !== state.id) {
          commit('SET_USER', user)
          if (!user) {
            Presence.clearPresence(state.firebasePresenceRefs)
            commit('SET_PRESENCE_REFS', {})
            return Promise.all([
              unbindFirestoreRef('userData'),
              unbindFirestoreRef('groups')
            ])
          }
          await Promise.all([
            bindFirestoreRef('userData', DB.collection('users').doc(user)),
            bindFirestoreRef(
              'memberships',
              DB.collectionGroup('members')
                .where('userId', '==', state.id)
                .orderBy('lastJoined', 'desc')
            )
          ])
          const refs = Presence.setupPresence()
          commit('SET_PRESENCE_REFS', refs)
        }
      }
    )
  }
}
