import Vue from 'vue'
import Rollbar from 'vue-rollbar'

Vue.use(Rollbar, {
  accessToken: process.env.VUE_APP_ROLLBAR_TOKEN,
  captureUncaught: true,
  enabled: true,
  environment: process.env.VUE_APP_ROLLBAR_ENV,
  payload: {
    client: {
      javascript: {
        code_version: '1.0',
        guess_uncaught_frames: true
      }
    }
  }
})
